import {MdxBody} from '../../components/MdxBody';
import {CurriculumVitaeLayout} from '../CurriculumVitaeLayout';
import {CmsPageBaseLayout} from './CmsPageBaseLayout';

export const CmsPageCurriculumVitaeLayout = (props: any) => {
  const {children, ...cmsPageBaseLayoutProps} = props;

  return (
    <CmsPageBaseLayout {...cmsPageBaseLayoutProps}>
      <CurriculumVitaeLayout>
        <MdxBody>
          {children}
        </MdxBody>
      </CurriculumVitaeLayout>
    </CmsPageBaseLayout>
  );
};

import { css } from '@emotion/core';
import {
  faFacebookF,
  faGithub,
  faGitlab,
  faLinkedinIn,
  faTelegramPlane,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope,
  faGlobe,
  faMapMarker,
  faPhone,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { Fragment, useCallback, useContext } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { MIN_TABLET_MEDIA_QUERY } from 'typography-breakpoint-constants';
import defaultLocale from '../../i18n/default-locale.json';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { PrimaryButton } from '../components/Button';
import { Container } from '../components/Container';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Link } from '../components/Link';
import { MainContent } from '../components/MainContent';
import { SafeText } from '../components/Safe';
import { CurrentPageContext } from '../contexts/CurrentPageContext';
import { colors } from '../styles/colors';
import { commonBoxShadow, linkCss } from '../styles/css';
import {
  font80,
  rhythm0_125,
  rhythm0_25,
  rhythm0_5,
  rhythm1,
  rhythm10,
  rhythm13,
  rhythm2,
  rhythm3,
} from '../styles/typography';
import { BaseLayout } from './BaseLayout';

if (!process.env.SITE_URL) {
  throw new Error(`${__filename} Missing process.env.SITE_URL`);
}

const messages = defineMessages({
  skillsTitle: {
    id: 'layouts.CurriculumVitaeLayout.skillsTitle',
    defaultMessage: 'My Skills',
  },
  printButton: {
    id: 'layouts.CurriculumVitaeLayout.printButton',
    defaultMessage: 'Print CV',
  },
  showSkillsButton: {
    id: 'layouts.CurriculumVitaeLayout.showSkillsButton',
    defaultMessage: 'My Skills',
  },
  businessAddress: {
    id: 'layouts.CurriculumVitaeLayout.businessAddress',
    defaultMessage: `{address}, {city}, {
    country, select,
      it { Italy}
  }`,
  },
});

const mainCss = css({
  // background: 'white',

  minHeight: [
    `calc(100vh - ${rhythm3})`,
    `calc(var(--window-height, 100vh) - ${rhythm3})`,
  ],

  padding: `${rhythm1} 0 ${rhythm2}`,

  '@media print': {
    padding: 0,
  },
});

const bodyCss = css({
  '& > p:last-child': {
    marginBottom: 0,
  },
});

const asideCss = css({
  // [MIN_TABLET_MEDIA_QUERY]: {
  //   ...cvStyle,
  // },
  ...font80,

  marginBottom: rhythm1,

  textAlign: 'center',
  // overflow: 'hidden',

  '@media print': {
    colorAdjust: 'exact',
    WebkitPrintColorAdjust: 'exact',
    // textAlign: 'right',
    maxWidth: rhythm13,
    marginLeft: rhythm1,
    marginBottom: 0,
    float: 'right',

    // borderRadius: rhythm0_5,

    boxSizing: 'content-box',
    // margin: `0 ${rhythm1} ${rhythm1} 0`,
    // borderStyle: 'solid',
    // borderWidth: '1px',
    background: '#eee',
    padding: rhythm0_5,
    // float: 'right',
    // marginLeft: rhythm0_5,
    width: rhythm10,
    textAlign: 'center',

    boxShadow: commonBoxShadow,
    marginRight: '10px',
    marginTop: '7px',
    borderRadius: '2px',

    '& a': {
      color: 'inherit',
    },
  },
});

const titleGridCss = css({
  [MIN_TABLET_MEDIA_QUERY]: {
    marginBottom: rhythm1,

    '& h1': {
      marginBottom: 0,
    },

    display: ['-ms-grid', 'grid'],
    gridTemplateColumns: 'auto min-content',
    msGridColumns: 'auto min-content',
    alignItems: 'center',
    gridGap: rhythm0_5,
  },
});

const actionButtonsCss = css({
  textAlign: 'center',
  // marginBottom: rhythm0_5,

  // '& button': {
  //   marginTop: rhythm0_25,

  //   [MIN_MOBILE_MEDIA_QUERY]: {
  //     marginTop: 0,
  //   },
  // },

  // '& button + button': {
  //   marginLeft: rhythm0_25,
  // },

  '@media print': {
    display: 'none',
  },
});

const hideOnMobileCss = css({
  display: 'none',

  [MIN_TABLET_MEDIA_QUERY]: {
    display: 'block',
  },
});

const skillsCss = css({
  listStyle: 'none',
  background: colors.backgrounds.light,
  padding: rhythm0_5,
  borderRadius: '2px',

  // [MIN_TABLET_MEDIA_QUERY]: {
  //   height: 'auto',
  //   transform: 'none',
  //   opacity: 1,
  //   marginTop: 0,
  // },

  '@media print': {
    margin: 0,
    height: 'auto',
    transform: 'none',
    opacity: 1,
    background: 'none',
  },

  '& h2': {
    ...font80,
    marginBottom: rhythm0_5,
    // textAlign: 'center',

    '@media print': {
      display: 'none',
    },
  },

  '& ul': {
    margin: 0,
    // padding: `${rhythm1} -${rhythm0_125}`,
  },

  '& li': {
    display: 'inline-block',
    margin: `${rhythm0_125}`,
    fontWeight: 600,
    // padding: `0 ${rhythm0_25}`,
    verticalAlign: 'middle',

    '@media print': {
      fontWeight: 400,
      // background: '#333',
      color: '#fff !important',
    },

    '& a': [
      linkCss,
      {
        color: 'inherit',
        fontWeight: 'inherit',
      },
    ],

    '& a, & span': {
      colorAdjust: 'exact',
      WebkitPrintColorAdjust: 'exact',
      padding: `0 ${rhythm0_25}`,
      color: '#fff',
      display: 'block',
      borderRadius: rhythm0_5,
      background: colors.body.text,
      overflow: 'hidden',
    },
  },
});

const openSkillsCss = css({
  // transform: 'translateY(0)',
  // height: 'auto',
  // opacity: 1,
  // background: colors.backgrounds.light,
  // padding: rhythm0_5,
  // borderRadius: '2px',
  // marginTop: rhythm0_5,
  // transition: `
  //   transform ${animationDuration}ms ease-out,
  //   opacity ${animationDuration}ms ease-out
  // `,
});

const pivaCss = css({
  display: 'none',

  '@media print': {
    display: 'block',
    // textAlign: 'center',
    fontWeight: 400,
  },
});

const contactCss = css({
  display: 'none',

  '@media print': {
    display: 'block',

    '& a': {
      display: 'flex',
      textDecoration: 'none',
      wordBreak: 'break-word',
      position: 'relative',
      alignItems: 'center',
      flexDirection: 'row-reverse',

      '& div': {
        flexGrow: 1,
        textAlign: 'right',
        marginRight: rhythm0_25,
      },
    },
  },
});

const headerCss = css({
  '@media print': {
    display: 'none',
  },
});

const footerCss = css({
  '@media print': {
    display: 'none',
  },
});

type Props = {
  children: React.ReactNode;
};

export const CurriculumVitaeLayout = (props: Props) => {
  const { children } = props;

  const {
    business,
    social,
    skills: { skills },
  } = useStaticQuery(query);

  const website = process.env.SITE_URL!.replace(/^https?:\/\//, '');

  const { locale } = useIntl();

  const handleClickPrint = useCallback(() => {
    window.print();
  }, []);

  // const [show, setShow] = useState(false);

  // const handleClickShowSkills = useCallback(
  //   (e: React.MouseEvent) => {
  //     e.stopPropagation();

  //     setShow((prev) => !prev);
  //   },
  //   [],
  // );

  const { pathname } = useLocation();

  const { title } = useContext(CurrentPageContext);

  return (
    <BaseLayout>
      <Header css={headerCss} />

      <Breadcrumbs />

      <div css={mainCss}>
        <MainContent key={pathname}>
          <main>
            <Container>
              <div css={titleGridCss}>
                <h1>{title}</h1>

                <div css={[actionButtonsCss, hideOnMobileCss]}>
                  <PrimaryButton onClick={handleClickPrint}>
                    <FontAwesomeIcon icon={faPrint} fixedWidth />{' '}
                    <FormattedMessage {...messages.printButton} />
                  </PrimaryButton>
                </div>
              </div>

              <aside css={asideCss}>
                {/* <div css={actionButtonsCss}>
                  <PrimaryButton onClick={handleClickPrint}>
                    <FontAwesomeIcon icon={faPrint} fixedWidth />
                    {' '}
                    <FormattedMessage {...messages.printButton} />
                  </PrimaryButton> */}

                {/* <DefaultButton onClick={handleClickShowSkills}>
                    <FormattedMessage {...messages.showSkillsButton} />
                    {' '}
                    {show && (
                      <FontAwesomeIcon icon={faChevronUp} fixedWidth />
                    )}

                    {!show && (
                      <FontAwesomeIcon icon={faChevronDown} fixedWidth />
                    )}
                  </DefaultButton> */}
                {/* </div> */}

                <div css={contactCss}>
                  <a href={process.env.SITE_URL!}>
                    <FontAwesomeIcon icon={faGlobe} fixedWidth />

                    <div>{website}</div>
                  </a>

                  <SafeText text={business.phoneSafe}>
                    {(text) =>
                      text && (
                        <Fragment>
                          <a href={`tel:${text}`}>
                            <FontAwesomeIcon icon={faPhone} fixedWidth />

                            <div>{text}</div>
                          </a>
                        </Fragment>
                      )
                    }
                  </SafeText>

                  <SafeText text={business.emailSafe}>
                    {(text) =>
                      text && (
                        <Fragment>
                          <a href={`mailto:${text}`}>
                            <FontAwesomeIcon icon={faEnvelope} fixedWidth />

                            <div>{text}</div>
                          </a>
                        </Fragment>
                      )
                    }
                  </SafeText>

                  {Object.entries<string>(social.links).map(
                    ([provider, url]) => {
                      if (provider === 'facebook') {
                        return (
                          <Link key={provider} href={url} target="_blank">
                            <FontAwesomeIcon icon={faFacebookF} fixedWidth />

                            <div>{social.usernames.facebook}</div>
                          </Link>
                        );
                      }

                      if (provider === 'linkedin') {
                        let linkedUrl = url;

                        if (locale === 'it') {
                          linkedUrl += '?locale=it_IT';
                        }

                        return (
                          <Link key={provider} href={linkedUrl} target="_blank">
                            <FontAwesomeIcon icon={faLinkedinIn} fixedWidth />

                            <div>{social.usernames.linkedin}</div>
                          </Link>
                        );
                      }

                      if (provider === 'whatsappSafe') {
                        return (
                          <SafeText key={provider} text={url}>
                            {(text) =>
                              text && (
                                <Link href={text} target="_blank">
                                  <FontAwesomeIcon
                                    icon={faWhatsapp}
                                    fixedWidth
                                  />

                                  <div>{text}</div>
                                </Link>
                              )
                            }
                          </SafeText>
                        );
                      }

                      if (provider === 'telegram') {
                        return (
                          <Link key={provider} href={url} target="_blank">
                            <FontAwesomeIcon
                              icon={faTelegramPlane}
                              fixedWidth
                            />

                            <div>{url}</div>
                          </Link>
                        );
                      }

                      if (provider === 'github') {
                        return (
                          <Link key={provider} href={url} target="_blank">
                            <FontAwesomeIcon icon={faGithub} fixedWidth />

                            <div>{social.usernames.github}</div>
                          </Link>
                        );
                      }

                      if (provider === 'gitlab') {
                        return (
                          <Link key={provider} href={url} target="_blank">
                            <FontAwesomeIcon icon={faGitlab} fixedWidth />

                            <div>{social.usernames.gitlab}</div>
                          </Link>
                        );
                      }

                      return null;
                    },
                  )}

                  <a
                    href={`geo:${[
                      business.geoPosition.lat,
                      business.geoPosition.lon,
                    ]}?q=${[
                      business.geoPosition.lat,
                      business.geoPosition.lon,
                    ]}`}
                  >
                    <FontAwesomeIcon icon={faMapMarker} fixedWidth />

                    <div>
                      <FormattedMessage
                        {...messages.businessAddress}
                        values={business}
                      />
                    </div>
                  </a>
                </div>

                <div css={[skillsCss, /* show &&  */ openSkillsCss]}>
                  <h2>
                    <FormattedMessage {...messages.skillsTitle} />
                  </h2>

                  <ul>
                    {skills.map(({ skill, links }: any) => {
                      const localeLink = links?.find(
                        (it: any) => it.locale === locale,
                      );
                      const defaultLocaleLink = links?.find(
                        (it: any) => it.locale === defaultLocale,
                      );

                      const link = localeLink || defaultLocaleLink || links[0];

                      return (
                        <li key={skill}>
                          {link && (
                            <Link
                              href={link.url}
                              target="_blank"
                              rel="noopener"
                            >
                              {skill}
                            </Link>
                          )}

                          {!link && <span>{skill}</span>}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                {/* <div css={pivaCss}>
                  CF: PRFMNL87D07F464Z
                  <br />
                  P.IVA: 03904670241
                </div> */}
              </aside>

              <div css={bodyCss}>{children}</div>
            </Container>
          </main>
        </MainContent>
      </div>

      <Footer css={footerCss} />
    </BaseLayout>
  );
};

export const query = graphql`
  query {
    business: settingsYaml(settings: { eq: "business" }) {
      vatId
      taxId
      address
      city
      state
      postalCode
      country
      phoneSafe
      emailSafe

      geoPosition {
        lon
        lat
      }
    }

    social: settingsYaml(settings: { eq: "social" }) {
      links {
        whatsappSafe
        telegram
        facebook
        linkedin
        github
        gitlab
      }

      usernames {
        facebook
        linkedin
        github
        gitlab
      }
    }

    skills: dataYaml(data: { eq: "skills" }) {
      skills {
        skill

        links {
          url
          locale
        }
      }
    }
  }
`;

CurriculumVitaeLayout.whyDidYouRender = true;
